import React from 'react';
import WeDash from '../../assets/img/logo.svg';
import { LeadFormSession } from '../../components';
import * as S from './styled';

const LeadForm = () => {
  return (
    <>
      <S.Container>
        <S.Content>
          <LeadFormSession
            logoSrc={WeDash}
            titleForm={['Preencha seus dados', 'e fale com um especialista:']}
            text={[
              [
                'A Bowe é especialista em Martech. O We.Dash é o nosso',
                'produto para você.',
              ],
              [
                'O We.Dash mapeia, reúne e traz a visualização dos principais',
                'indicadores de inbound marketing, CRM, ferramentas de',
                'automação e analytics.',
              ],
              ['O dashboard da Bowe leva o nome de We.Dash.'],
              [
                'Uma tecnologia que permite visualizar todas as informações',
                'que precisa em um único dash.',
              ],
              [
                'Um dashboard feito por nós para que sua equipe de marketing e',
                'vendas possa visualizar todas as informações de forma rápida',
                'e fácil.',
              ],
              [
                'We.Dash automatiza todo o seu processo de dados.',
                'Fale agora mesmo com um de nossos especialistas.',
              ],
            ]}
          />
        </S.Content>
      </S.Container>
    </>
  );
};

export default LeadForm;
