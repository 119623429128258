import styled from 'styled-components';
import BLUE_LINE from '../../assets/img/blueLineImg.svg';
import GREEN_LINE from '../../assets/img/greenLineImg.svg';
import WOMAN from '../../assets/img/womanIpadImg.webp';

export const Container = styled.div`
  position: relative;
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;

  :after {
    position: absolute;
    content: '';
    background-image: url(${BLUE_LINE});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    bottom: 0%;
    left: 0%;
    width: 98vw;
    height: 650px;
    z-index: -2;
  }
  :before {
    position: absolute;
    content: '';
    background-image: url(${GREEN_LINE});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    bottom: 0;
    left: -2%;
    width: 98vw;
    height: 650px;
    z-index: -2;
  }
`;

export const Content = styled.div`
  position: relative;

  :after {
    position: absolute;
    content: '';
    background-image: url(${WOMAN});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 5%;
    right: 3%;
    width: 500px;
    height: 700px;
    z-index: -1;
  }

  @media (max-width: 1920px) {
    :after {
      top: 5%;
      right: 0%;
      width: 400px;
      height: 600px;
    }
  }
  @media (max-width: 1680px) {
    :after {
      top: 2%;
      right: 0%;
      width: 350px;
      height: 600px;
    }
  }
  @media (max-width: 1600px) {
    :after {
      top: 2%;
      right: 0%;
      width: 300px;
      height: 600px;
    }
  }
  @media (max-width: 1440px) {
    :after {
      top: 2%;
      right: 0%;
      width: 200px;
      height: 500px;
    }
  }
  @media (max-width: 1024px) {
    :after {
      display: none;
    }
  }
`;
