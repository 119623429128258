import { PageWrapper } from '../components';
import LeadForm from '../templates/LeadForm';

export default PageWrapper(
  LeadForm,
  'We.Dash | Dashboards De Marketing, Vendas e Growth',
  'https://wedash.digital/lead-form',
  'Todas Campanhas De Marketing e Vendas Em Única Plataforma Com Dashboards Intuitivos. We.Dash Te Ajuda a Tomar As Melhores Decisões Nos Negócios. Comece Já!',
  'wedash-by-bowe',
);
